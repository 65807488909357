import React, { useContext, useEffect, useState } from 'react'
import './Journals.css'
import AuthContext from '../context/AuthContext2'
import { useNavigate, Link } from 'react-router-dom'

const Journals = () => {

    let {communicate, logoutUser} = useContext(AuthContext)
    let navigate = useNavigate()
    let [journals, setJournals] = useState([])
    let [publications, setPublications] = useState([])
    let [isReady, setIsReady] = useState(false)
    let [resultError, setResultError] = useState(false)
    let [isSuperAdmin, setIsSuperAdmin] = useState(false)
    
    const handleJournals = (result) => {
        if (result?.done) {
            setJournals(result.journals)
            if (result.publications !== undefined) {
                setPublications(result.publications)
            }
            setResultError(false)
        }
        else if (result?.error !== undefined) {
            console.log(result.error)
            setResultError(true)
        }
        else {
            setResultError(true)
        }
        setIsReady(true)
    }

    const changeCounter = (n) => {
        communicate('/api/decrease-counter', handleChangeCounter, 'POST', {'pID': publications[0].unique_id, 'n': n})
    }

    const resetCounter = () => {
        communicate('/api/reset-counter', handleResetCounter, 'POST', {'pID': publications[0].unique_id})
    }

    const handleChangeCounter = (result) => {
        if (result?.done) {
            const newpublication = publications[0]
            newpublication.counter = result.counter
            setPublications([newpublication])
        }
        else if (result?.error !== undefined) {
            console.log(result.error)
        }
    }

    const handleResetCounter = (result) => {
        if (result?.done) {
            const newpublication = publications[0]
            newpublication.counter = result.counter
            setPublications([newpublication])
        }
        else if (result?.error !== undefined) {
            console.log(result.error)
        }
    }

    useEffect(() => {
        document.title = 'Journals | Science Proof';
        communicate('/api/get-user-journals', handleJournals)
    }, [])

    useEffect(() => {
        if (publications.length > 0) {
            if (publications[0].isSuperAdmin) {
                setIsSuperAdmin(true)
            }
        }
    }, [publications])

    // return (
    //     <div className='journals'>
    //         {((journals.length !== 0) && isReady) && <ul>
    //             {journals.map((journal, id) => (
    //                 <li key={id}><img src={journal.logo} alt={journal.unique_id}/><Link to={`/${journal.unique_id}/${'dashboard'}`}>{journal.title}</Link>{(journal.is_admin && journal.counter !== undefined) ? ` (${journal.counter})` : ''}</li>
    //             ))}
    //         </ul>}
    //         {((journals.length === 0) && !resultError && isReady) && 'You are not a member of any journals'}
    //         {((journals.length === 0) && resultError && isReady) && 'Something went wrong'}
    //         {((publications.length !== 0) && isReady)  && <div className='journals-counter'>
    //             <p>Pending payment: {publications[0].counter}</p>
    //             {isSuperAdmin && <div className='journals-counter-controls'>
    //                 <Link onClick={() => changeCounter(10)}>-10</Link>
    //                 <Link onClick={() => changeCounter(5)}>-5</Link>
    //                 <Link onClick={() => changeCounter(1)}>-1</Link>
    //                 <Link onClick={() => resetCounter()}>reset</Link>
    //                 <Link onClick={() => changeCounter(-1)}>+1</Link>
    //                 <Link onClick={() => changeCounter(-5)}>+5</Link>
    //                 <Link onClick={() => changeCounter(-10)}>+10</Link>
    //             </div>}
    //         </div>}
    //         <div>
    //             <Link className='journals-logout' onClick={logoutUser}>Logout</Link>
    //         </div>
    //     </div>
    //     // <div className='journals'>
    //     //     {((journals.length !== 0) && isReady) && <ul>
    //     //         {journals.map((journal, id) => (
    //     //             <li key={id}><Link to={`/${journal.unique_id}/${'dashboard'}`}>{journal.title}</Link>{(journal.is_admin && journal.counter !== undefined) ? ` (${journal.counter})` : ''}</li>
    //     //         ))}
    //     //     </ul>}
    //     //     {((journals.length === 0) && !resultError && isReady) && 'You are not a member of any journals'}
    //     //     {((journals.length === 0) && resultError && isReady) && 'Something went wrong'}
    //     //     <div>
    //     //         <Link className='journals-logout' onClick={logoutUser}>Logout</Link>
    //     //     </div>
    //     // </div>
    // )

    return (
        <div className='journals'>
            {((journals.length !== 0) && isReady) && 
            <div className='journals-table'>
                <table>
                    {journals.map((journal, id) => (
                        <tr key={id} onClick={() => {navigate(`/${journal.unique_id}/${'dashboard'}`)}}>
                            <td><img src={journal.logo} alt={journal.unique_id}/></td>
                            <td>{journal.title}{(journal.is_admin && journal.counter !== undefined) ? ` (${journal.counter})` : ''}</td>
                        </tr>
                    ))}
                </table>
            </div>}
            {((journals.length === 0) && !resultError && isReady) && 'You are not a member of any journals'}
            {((journals.length === 0) && resultError && isReady) && 'Something went wrong'}
            {((publications.length !== 0) && isReady)  && <div className='journals-counter'>
                <p>Pending payment: {publications[0].counter}</p>
                {isSuperAdmin && <div className='journals-counter-controls'>
                    <Link className='journals-counter-button' onClick={() => changeCounter(10)}>-10</Link>
                    <Link className='journals-counter-button' onClick={() => changeCounter(5)}>-5</Link>
                    <Link className='journals-counter-button' onClick={() => changeCounter(1)}>-1</Link>
                    <Link className='journals-counter-button' onClick={() => resetCounter()}>reset</Link>
                    <Link className='journals-counter-button' onClick={() => changeCounter(-1)}>+1</Link>
                    <Link className='journals-counter-button' onClick={() => changeCounter(-5)}>+5</Link>
                    <Link className='journals-counter-button' onClick={() => changeCounter(-10)}>+10</Link>
                </div>}
                <p>Total: {publications[0].totalCounter}</p>
            </div>}
            <div>
                <Link className='journals-logout' onClick={logoutUser}>Logout</Link>
            </div>
        </div>
        // <div className='journals'>
        //     {((journals.length !== 0) && isReady) && <ul>
        //         {journals.map((journal, id) => (
        //             <li key={id}><Link to={`/${journal.unique_id}/${'dashboard'}`}>{journal.title}</Link>{(journal.is_admin && journal.counter !== undefined) ? ` (${journal.counter})` : ''}</li>
        //         ))}
        //     </ul>}
        //     {((journals.length === 0) && !resultError && isReady) && 'You are not a member of any journals'}
        //     {((journals.length === 0) && resultError && isReady) && 'Something went wrong'}
        //     <div>
        //         <Link className='journals-logout' onClick={logoutUser}>Logout</Link>
        //     </div>
        // </div>
    )
}

export default Journals